import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Section,
  Container,
  SectionImageBkg,
  MarkdownContent,
  BreadCrumb,
  ExploreMore,
} from "../components/Section";
import {
  HeroBanner,
  HeroTitle,
  HeroCaption,
  HeroContent,
  HeroLeft,
  HeroRight,
  HeroCard,

} from "../components/HeroBanner";
import QuotePopUpButton from "../components/QuotePopUpButton";
import DealerForm from "../components/DealerForm";
import { commonSchema } from "../schema";

const Sections = styled(Section)`
  &:before{		
		display:${props => (props.bgBefore ? 'block' : 'none')};		
    height:90%;
    @media (min-width: 992px) {
		  height:${props => props.bgBeforeHeight};
    }
	}
`;

const SectionImageBkgs = styled(SectionImageBkg)`
  width: auto;
  height: auto;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  @media (min-width: 1600px) {
    display: block;
    right: -15%;
  }
  @media (min-width: 1900px) {
    right: -10%;
  }
`;



const ContentPageTemplate = ({ data, location }) => {
  const pageData = data.contentfulContentPages;
  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        schemaMarkup={commonSchema}
        location={location}
      />
      <Sections
        bgColor="#F8FAFB"
        bgBefore="#002765"
        bgBeforeHeight="80%"
        bgBeforeWidth="100%"
      >
        <HeroBanner
          className="banner-hero"
          bgBefore="block"
          data-shadowtext={pageData.heroTitle}
          beforeAlign="center"
          beforeWidth="100%"
        >
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb>
                <Link to="/">Home</Link>
                <span>{pageData.heroTitle}</span>
              </BreadCrumb>
              <HeroContent>
                {location.pathname === "/become-dealer/" ? (
                  <>
                  <HeroLeft><HeroTitle>{pageData.heroTitle}</HeroTitle></HeroLeft>
                  <HeroRight>
                    <HeroCard><DealerForm location={location} /></HeroCard>
                  </HeroRight>
                  </>
                ) : (
                  <HeroTitle>{pageData.heroTitle}</HeroTitle>
                )}
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Sections>
      <Section
        ept="0"
        epb="120px"
        xpt="0"
        xpb="80px"
        pt="0"
        pb="40px"
        bgColor="#F8FAFB"
        overflow="hidden"
      >
        {pageData.contentSectionBg?.gatsbyImageData && (
          <SectionImageBkgs>
            <GatsbyImage
              image={pageData.contentSectionBg.gatsbyImageData}
              alt={pageData.contentSectionBg.title}
            />
          </SectionImageBkgs>
        )}
        <Container maxWidth="1280px">
          <MarkdownContent>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
          {pageData.url === "/become-dealer" && (
            <ExploreMore mt="30px" justifyContent="left">
              <QuotePopUpButton btntext="Get a Quote" />
            </ExploreMore>
          )}
        </Container>
      </Section>
    </Layout>
  );
};

export default ContentPageTemplate;

export const pageQuery = graphql`
  query ContentPageTemplateQuery($id: String!) {
    contentfulContentPages(id: { eq: $id }) {
      metaTitle
      metaDescription
      url
      heroTitle
      content {
        childMarkdownRemark {
          html
        }
      }
      contentSectionBg {
        title
        gatsbyImageData(quality: 50, placeholder: BLURRED)
      }
    }
  }
`;
